import styled, {css} from 'styled-components';
import menu from '../../assets/icon-menu.png';


interface ContainerProps {
    isMobile: boolean;
}


export const Container = styled.div<ContainerProps>`
    position: fixed;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    width: 100%;
    background: transparent;
    @media(max-width: 1011px) {
        position: absolute;
        right: 0;
        justify-content: space-between;
        align-items: center;
        padding: 0px;
  }
`;

export const Logo = styled.div`
    img{
        width: 90px;
        margin-right: 5vw;
    }
    @media(max-width: 1011px) {
        img{
        width: 50px;
        margin-top: 5px;
    }
  }
`;


export const Hr = styled.hr`
    position: fixed;
    top:9vh;
    border: 1px solid white;
    width: 99vw;
    @media(max-width: 1011px) {
        display: none;
    }
`;

export const Itens = styled.nav<ContainerProps>`
    width: 70%;
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    ${props =>
    props.isMobile &&
    css`
    background: url(${menu}) no-repeat center;
    height: 80px;
    position: absolute;
    right:0;
    width: 10%;
    height: 100%;
    a{
        display: none;
    }
    `}
  > svg {
    margin: 4px 12px 0 0;
  }

    a{
        color: white;
        font-size: 1.2em;
    }
    a:hover{
        border-bottom: 2px solid #E23802;
        color: #E23802;
    }
    div{
        display: flex;
        justify-content: space-around;
        align-items: flex-start;
        width: 40%;
        margin-left: 30vw;
    }
    @media(max-width: 1011px) {
       div{
        display: flex;
        flex-direction: column;
        width: 50%;
        align-items: end;
       }
        a{
        white-space: nowrap;

        }
        justify-content: space-around;
        align-items: flex-start;
    }
`;
