import styled from 'styled-components';


export const Container = styled.footer`
    background: #E23802;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-bottom: 20px;
    strong{
        a{
            margin-left: 5px;
            color: white;
        }
    }

    @media(max-width: 1011px) {
        text-align: center;
        font-size: 13px;
    }
`;

export const RedesSociais = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 18px 0;

    section { 
        margin: 0 18px;
        img{
            height: 24px;
        }
    }

    @media(max-width: 1011px) {
        section { 
        img{
            height: 18px;
        }
    }
    }
`;
