import React, { useState, useCallback } from "react";
import { FiUser } from "react-icons/fi";
import { Form } from "@unform/web";
import { useHistory } from "react-router-dom";

import logoImg from "../../assets/alterlogo.svg";

import Input from "../../components/input/input";
import Button from "../../components/button/button";

import { Container, Content, Background, AnimationContainer } from "./styles";

const SingIn: React.FC = () => {
  const history = useHistory();
  const [nome, setNome] = useState("");

  const handleSubmit = useCallback(() => {
    history.push("/dashboard");
  }, [history]);

  return (
    <Container>
      <Content>
        <AnimationContainer>
          <Form onSubmit={handleSubmit}>
            <img src={logoImg} alt="SeaFriends" />
            <h1>Digite seu nome e entre</h1>

            <Input
              icon={FiUser}
              name="nome"
              placeholder="nome"
              value={nome}
              onChange={(e) => {
                setNome(e.target.value);
              }}
            />

            <Button type="submit" icon={<p />} h="62px" w="100%" to="dashboard">
              Entrar
            </Button>
          </Form>

          {/**
                     * 
                     <Link to="/cadastro">
                        <FiLogIn/>
                        Cadastre-se
                    </Link>
                     */}
        </AnimationContainer>
      </Content>
      <Background>
        <h1>
          O gibi <br /> digital feito <br /> <span>para você!</span>
          <hr />
        </h1>
      </Background>
    </Container>
  );
};

export default SingIn;
