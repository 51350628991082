import React, { useCallback, useState } from "react";
import { Container, Content, Background, ButtonsArea } from "./styles";

import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import manual from "../../utils/manual";

const Manual: React.FC = () => {
  const [count, setCount] = useState(0);
  const [thisPage, setThisPage] = useState([manual[count]]);

  const nextPage = useCallback(() => {
    if (count < 10) {
      const newCont = count + 1;
      setCount(newCont);
      setThisPage([manual[newCont]]);
      return;
    } else {
      setCount(10);
    }

    setThisPage([manual[count]]);
  }, [thisPage]);

  const backPage = useCallback(() => {
    if (count >= 1) {
      const newCont = count - 1;
      setCount(newCont);
      setThisPage([manual[newCont]]);
      return;
    } else {
      setCount(0);
    }
    setThisPage([manual[count]]);
  }, [thisPage]);
  return (
    <>
      <Container>
        <Header />
        <Content>
          {thisPage.map((page) => {
            return (
              <section key={page.id}>
                <span>
                  <img src={page.image} alt={`Pagína ${page.id} do Gibi`} />
                </span>
                <ButtonsArea>
                  <button
                    type="button"
                    title="Voltar"
                    disabled={count === 0}
                    onClick={backPage}
                  >
                    <AiOutlineArrowLeft />
                  </button>
                  Pag {count + 1} de {manual.length}
                  <button
                    type="button"
                    title="Avançar"
                    disabled={count === manual.length - 1}
                    onClick={nextPage}
                  >
                    <AiOutlineArrowRight />
                  </button>
                </ButtonsArea>
              </section>
            );
          })}
        </Content>
      </Container>
      <Footer />
    </>
  );
};

export default Manual;
