import { LinkProps } from "react-router-dom";
import { ReactElement } from "react";

import { Container, LinkButton } from "./styles";

interface NavLinkProps extends LinkProps {
  icon: ReactElement;
  children: string;
  h: string | null;
  w: string | null;
  href?: string;
}

export default function Button({
  icon,
  children,
  color = "red",
  h,
  w,
  to,
  href,
}: NavLinkProps) {
  return (
    <>
      <Container color={color} h={h} w={w}>
        {href ? (
          <a href={href}>
            <span>{icon}</span>

            {children}
          </a>
        ) : (
          <LinkButton to={to}>
            <span>{icon}</span>

            {children}
          </LinkButton>
        )}
      </Container>
    </>
  );
}
