import styled from 'styled-components';
import {Link as LinkRouter} from "react-router-dom";

import {shade} from 'polished';

type ButtonProps = {
    color: string | null;
    h: string | null;
    w: string | null;
}

export const Container = styled.button<ButtonProps>`
  background: #E23802;
  border-radius: 10px;
  border: 0;
  padding: 0 16px;
  width:${(props) => props.w ? props.w : '12000px'};
  height:${(props) => props.h ? props.h : '65px'};
  color:  ${(props) => props.color ? props.color : '#FFFF'};
  font-weight: 500;
  margin-top: 16px;
  transition: background-color 0.2s;
  font-size: 20px;
  &:hover {
    background: ${shade(0.2, '#E23802')};
  }
  display: flex;
  justify-content: center;
  align-items: center;
  a{ 
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 65%;
    text-decoration: none;
    color: white;
  }

  Link{

  }
`;

export const LinkButton = styled(LinkRouter)`
  color: white;
  white-space: nowrap;
  display: flex;

  span{
    padding-right: 5px;
    background: #E23802;
  }
 
`;