import styled, {css} from 'styled-components';

//import Tooltip from '../Tooltip/index';

interface ContainerProps {
    isFocused: boolean;
    isField: boolean;
    isErrored: boolean;
}

export const Container = styled.div<ContainerProps>`
  border-radius: 10px;
  padding: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  border: 2px solid rgba(0, 0, 0, 0.4);
  color: #666660;
  ${props =>
    props.isErrored &&
    css`
      color: #c53030;
    `}
  ${props =>
    props.isFocused &&
    css`
      color: #256EFF;
      border-color: #256EFF;

    `}
  ${props =>
    props.isField &&
    css`
      color: #256EFF;
    `}
  & + div {
    margin-top: 8px;
  }
  input {
    flex: 1;
    background: transparent;
    border: 0;
    color: #256EFF;
    &::placeholder {
      color: #666660;
    }
  }
  svg {
    margin-right: 16px;
  }
`;
