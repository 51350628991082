import React from "react";
import { IoBookSharp } from "react-icons/io5";
import { HiOutlineShoppingBag } from "react-icons/hi";
import homept from "../../assets/homept.png";

import whatsapp from "../../assets/whatsapp.png";
import instagram from "../../assets/instagram.png";
import facebook from "../../assets/facebook.png";

import Button from "../../components/button/button";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";

import {
  Container,
  Content,
  Background,
  Title,
  TextArea,
  ButtonsArea,
  SubContainer,
  SobreNos,
  BackgroundIconLeft,
  SobreTexto,
  EntreContato,
} from "./styles";

const SingIn: React.FC = () => {
  return (
    <Container>
      <Header />
      <SubContainer>
        <Content>
          <TextArea>
            <Title>Bem vindo!</Title>
            <p>
              Obrigado por nos acessar, aqui você faz parte do primeiro projeto
              de gibi digital inclusivo onde o foco principal é contar a
              história das criaturas marinhas.
            </p>
            <ButtonsArea>
              <Button
                type="submit"
                icon={<IoBookSharp />}
                h="65px"
                w="354px"
                color="white"
                to={"/meuconteudo"}
              >
                Meus conteúdos
              </Button>
              <Button
                type="submit"
                icon={<HiOutlineShoppingBag />}
                h="65px"
                w="354px"
                color="white"
                to={"/loja"}
              >
                Ver gibis disponíveis
              </Button>
            </ButtonsArea>
          </TextArea>
        </Content>
        <Background />
      </SubContainer>

      <SobreNos id="sobrenos">
        <BackgroundIconLeft>
          <img src={homept} alt="Sea Friends" />
        </BackgroundIconLeft>
        <SobreTexto>
          <div>
            <h1>O que é o Sea Friends?</h1>
            <p>
              Sea friends é um gibi digital que foi criado pensando na
              tecnologia e na inclusão digital.Ele ensina biologia marinha de
              uma forma lúdica e divertida.Através da leitura do gibi você irá
              conhecer a história de quatro amigos que juntos eles irão te
              ensinar um pouco mais sobre a vida marinha.
            </p>
            <p>
              O gibi sea friends contêm 4 versões:português,inglês,áudio
              visual(para cegos) e uma versão em libras.
            </p>
          </div>
        </SobreTexto>
      </SobreNos>

      <EntreContato id="contato">
        <h1>Entre em contato conosco</h1>
        <hr />
        <div>
          <a href="https://api.whatsapp.com/send?phone=558399736024&text=Ol%C3%A1%2C%20vim%20atrav%C3%A9s%20do%20site%20do%20Sea%20Friends">
            <img src={whatsapp} alt="Whatsapp" />
          </a>

          <a href="https://instagram.com/projeto.seafriends?utm_medium=copy_link">
            <img src={instagram} alt="Instagram" />
          </a>
          <a href="mailto:seafriendsproject@gmail.com">
            <img src={facebook} alt="Facebook" />
          </a>
        </div>
      </EntreContato>
      <Footer />
    </Container>
  );
};

export default SingIn;
