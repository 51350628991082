import React from "react";

import { Container, SubContainer, Gibis, Card, Botao } from "./styles";

import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";

import cardGibiPt from "../../assets/card-gibi-pt.png";
import Button from "../../components/button/button";
import { IoIosLogOut } from "react-icons/io";

const Loja: React.FC = () => {
  return (
    <Container>
      <Header />

      <SubContainer>
        <div>
          <section>
            <h1>
              Conteúdos <br />
              disponíveis
            </h1>
            <p>
              Seus gibis estarão disponíveis até a data informada pelo sistema.
            </p>
          </section>
        </div>
      </SubContainer>
      <Gibis>
        <Card>
          <span>Disponível até 22/06/2023</span>
          <img src={cardGibiPt} alt="Sea Friends" />
          <section>
            <h2>Sea Friends - Inglês</h2>
            <hr />
            <p>Uma versão totalmente em inglês,onde vo..</p>

            <Botao>
              <Button
                icon={
                  <IoIosLogOut
                    color="white"
                    style={{ background: "#E23802" }}
                  />
                }
                href={""}
                h="66px"
                w="100%"
                to={"/gibi/en"}
              >
                Ir para o gibi
              </Button>
            </Botao>
          </section>
        </Card>

        <Card>
          <span>Disponível até 22/06/2023</span>
          <img src={cardGibiPt} alt="Sea Friends" />
          <section>
            <h2>Sea Friends - Libras</h2>
            <hr />
            <p>Uma versão criada para pessoas sur...</p>

            <Botao>
              <Button
                icon={<IoIosLogOut color="white" />}
                href={""}
                h="66px"
                w="100%"
                to={"/gibi/libras"}
              >
                Ir para o gibi
              </Button>
            </Botao>
          </section>
        </Card>

        <Card>
          <span>Disponível até 22/06/2023</span>
          <img src={cardGibiPt} alt="Sea Friends" />
          <section>
            <h2>Sea Friends - Português</h2>
            <hr />
            <p>Essa versão em português conta a história de...</p>

            <Botao>
              <Button
                icon={<IoIosLogOut color="white" />}
                href={""}
                h="66px"
                w="100%"
                to={"/gibi"}
              >
                Ir para o gibi
              </Button>
            </Botao>
          </section>
        </Card>

        <Card>
          <span>Disponível até 22/06/2023</span>
          <img src={cardGibiPt} alt="Sea Friends" />
          <section>
            <h2>Sea Friends - Audio</h2>
            <hr />
            <p>Uma versão criada para pessoas ceg...</p>

            <Botao>
              <Button
                icon={
                  <IoIosLogOut
                    color="white"
                    style={{ background: "#E23802" }}
                  />
                }
                href={""}
                h="66px"
                w="100%"
                to={"/gibi/audio"}
              >
                Ir para o gibi
              </Button>
            </Botao>
          </section>
        </Card>
      </Gibis>
      <Footer />
    </Container>
  );
};

export default Loja;
