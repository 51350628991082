const gibiVIDEO = [
    {
        id: "1",
        urlVideo: "https://www.youtube.com/embed/ntrDMhHlOgk",
        imagePT: require("../gibiSVG/pt/page1.svg").default,
    },
    {
        id: "2",
        urlVideo: "https://www.youtube.com/embed/hqQnRvTPTX8",
        imagePT: require("../gibiSVG/pt/page2.svg").default,

    },
    {
        id: "3",
        urlVideo: "https://www.youtube.com/embed/XqsOV9NMKgE",
        imagePT: require("../gibiSVG/pt/page3.svg").default,

    },
    {
        id: "4",
        urlVideo: "https://www.youtube.com/embed/bjClDCza3mU",
        imagePT: require("../gibiSVG/pt/page4.svg").default,
    },
    {
        id: "5",
        urlVideo: "https://www.youtube.com/embed/eLAfKi__LIM",
        imagePT: require("../gibiSVG/pt/page5.svg").default,
    },
    {
        id: "6",
        urlVideo: "https://www.youtube.com/embed/HXFz270L3W8",
        imagePT: require("../gibiSVG/pt/page6.svg").default,
    },
    {
        id: "7",
        urlVideo: "https://www.youtube.com/embed/U_IHBibyaNc",
        imagePT: require("../gibiSVG/pt/page7.svg").default,
    },
    {
        id: "8",
        urlVideo: "https://www.youtube.com/embed/xaMXUjyF4eo",
        imagePT: require("../gibiSVG/pt/page8.svg").default,
    },
    {
        id: "9",
        urlVideo: "https://www.youtube.com/embed/iFTExvhGqGs",
        imagePT: require("../gibiSVG/pt/page9.svg").default,
    },
    {
        id: "10",
        urlVideo: "https://www.youtube.com/embed/k_G7BHGn_Bc",
        imagePT: require("../gibiSVG/pt/page10.svg").default,
    },
    {
        id: "11",
        urlVideo: "https://www.youtube.com/embed/oKqwffYQBFc",
        imagePT: require("../gibiSVG/pt/page11.svg").default,
    },
    {
        id: "12",
        urlVideo: "https://www.youtube.com/embed/JSwZEBXhNnA",
        imagePT: require("../gibiSVG/pt/page12.svg").default,
    },
    {
        id: "13",
        urlVideo: "https://www.youtube.com/embed/20TPki9YwGg",
        imagePT: require("../gibiSVG/pt/page13.svg").default,
    },
    {
        id: "14",
        urlVideo: "https://www.youtube.com/embed/gwd5D0fgQkI",
        imagePT: require("../gibiSVG/pt/page14.svg").default,
    },
    {
        id: "15",
        urlVideo: "https://www.youtube.com/embed/HyVrA3QSWSE",
        imagePT: require("../gibiSVG/pt/page15.svg").default,
    },
    {
        id: "16",
        urlVideo: "https://www.youtube.com/embed/uFVxLjcxPes",
        imagePT: require("../gibiSVG/pt/page16.svg").default,
    },
    {
        id: "17",
        urlVideo: "https://www.youtube.com/embed/6Osrl6CM90o",
        image: require("../gibiSVG/pt/page17.svg").default,
    },
    {
        id: "18",
        urlVideo: "https://www.youtube.com/embed/AD8UA3Fmp78",
        image: require("../gibiSVG/pt/page18.svg").default,
    },
    {
        id: "19",
        urlVideo: "https://www.youtube.com/embed/p_H7fK8EXqc",
        imagePT: require("../gibiSVG/pt/page19.svg").default,
    },
]


export default gibiVIDEO;