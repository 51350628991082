import React from "react";

import { Container, RedesSociais } from "./styles";

import whatsapp from "../../assets/whatsapp.png";
import instagram from "../../assets/instagram.png";
import facebook from "../../assets/facebook.png";

export default function Footer() {
  return (
    <Container>
      <RedesSociais>
        <section>
          <a href="https://api.whatsapp.com/send?phone=558399736024&text=Ol%C3%A1%2C%20vim%20atrav%C3%A9s%20do%20site%20do%20Sea%20Friends">
            <img src={whatsapp} alt="Whatsapp" />
          </a>{" "}
        </section>
        <section>
          <a href="https://instagram.com/projeto.seafriends?utm_medium=copy_link">
            <img src={instagram} alt="Instagram" />
          </a>
        </section>
        <section>
          <a href="mailto:seafriendsproject@gmail.com">
            <img src={facebook} alt="Facebook" />
          </a>{" "}
        </section>
      </RedesSociais>
      <div>
        <strong>
          Copyright ©2021 All rights reserved | This template is made with by
          <a href="https://github.com/farciomernandes">
            Marcio Fernandes dos Santos
          </a>
        </strong>
      </div>
    </Container>
  );
}
