import React from "react";
import { Container, Content, ContainerContent } from "./styles";

import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";

// @ts-ignore
import gibiAudioMP3 from "../../utils/GibiAudio.mp3";

const LeituraLIBRAS: React.FC = () => {
  return (
    <>
      <Container>
        <Header />
        <Content>
          <ContainerContent>
            <audio controls>
              <source src={gibiAudioMP3} type="audio/mpeg" />
              Seu navegador não suporta áudio tag.
            </audio>
          </ContainerContent>
        </Content>
      </Container>
      <Footer />
    </>
  );
};

export default LeituraLIBRAS;
