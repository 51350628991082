import manualpt from '../../assets/manualpt.png';
import styled from 'styled-components';


export const Container = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #256EFF;
`;


export const Content = styled.section`
  margin-top: 10vh;
  display: flex;
  height: 800px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 18.3px;
  
  section{ 
    height: 100%;
    background-color: #FFE830;
    display: flex;
    flex-direction: column;
    width: 450px;
    justify-content: space-between;
    align-items: center;
    border-radius: 18px;
    span{
      height: 100%;
      justify-content: flex-start;
      align-items: flex-start;
      display: flex;
      img{
        width: 100%;
        height: 100%;
        position: relative;
        bottom: 12px;
        border-radius: 18px;
      }
    }
  }

  @media (max-width: 1011px){
    height: 100%;
    section{
      width: 95%;
      height: 90%;
    }
  }
`;

export const ButtonsArea = styled.div`
  width: 50%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: black !important;
  span{
    background: #E23802;
    border-radius: 10px;
    padding: 10px;
    cursor: pointer;
    color: white;
  }
  margin: 10px 0;
  @media (max-width: 1011px){
    width: 70%;
    margin: 0 0;
    span{
      padding: 7px;
    }
    margin-bottom: 4px;
  }
`;
export const Background = styled.section`
  height: 100%;
  width: 400%;
  background: url(${manualpt}) no-repeat local;
  background-size: cover;
  border-radius: 18px;
`;
