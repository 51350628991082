const gibiPT = [
    {
        id: "1",
        imagePT: require("../gibiSVG/pt/page1.svg").default,
    },
    {
        id: "2",
        imagePT: require("../gibiSVG/pt/page2.svg").default,
    },
    {
        id: "3",
        imagePT: require("../gibiSVG/pt/page3.svg").default,
    },
    {
        id: "4",
        imagePT: require("../gibiSVG/pt/page4.svg").default,
    },
    {
        id: "5",
        imagePT: require("../gibiSVG/pt/page5.svg").default,
    },
    {
        id: "6",
        imagePT: require("../gibiSVG/pt/page6.svg").default,
    },
    {
        id: "7",
        imagePT: require("../gibiSVG/pt/page7.svg").default,
    },
    {
        id: "8",
        imagePT: require("../gibiSVG/pt/page8.svg").default,
    },
    {
        id: "9",
        imagePT: require("../gibiSVG/pt/page9.svg").default,
    },
    {
        id: "10",
        imagePT: require("../gibiSVG/pt/page10.svg").default,
    },
    {
        id: "11",
        imagePT: require("../gibiSVG/pt/page11.svg").default,
    },
    {
        id: "12",
        imagePT: require("../gibiSVG/pt/page12.svg").default,
    },
    {
        id: "13",
        imagePT: require("../gibiSVG/pt/page13.svg").default,
    },
    {
        id: "14",
        imagePT: require("../gibiSVG/pt/page14.svg").default,
    },
    {
        id: "15",
        imagePT: require("../gibiSVG/pt/page15.svg").default,
    },
    {
        id: "16",
        imagePT: require("../gibiSVG/pt/page16.svg").default,
    },
    {
        id: "17",
        imagePT: require("../gibiSVG/pt/page17.svg").default,
    },
    {
        id: "18",
        imagePT: require("../gibiSVG/pt/page18.svg").default,
    },
    {
        id: "19",
        imagePT: require("../gibiSVG/pt/page19.svg").default,
    },
]


export default gibiPT;