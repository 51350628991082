import styled from 'styled-components';
import dashboardKid from '../../assets/dashboard-kid.png';
import leftBlue from '../../assets/left-blue.png';


export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background: #256EFF;
 
`;

export const SubContainer = styled.div`
  height: 95vh;
  display: flex;
  align-items: stretch;
  @media(max-width: 1011px) {
    flex-direction: column;
    height: 140vh;
  }
`;

export const Content = styled.section`
  display: flex;
  flex-direction: column;
  @media(max-width: 1011px) {
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding-top: 8vh;
    max-width: 100vw;
  }
  align-items: center;
  justify-content: flex-start;
  padding-top: 8vh;
  width: 100%;
  max-width: 50vw;
`;


export const Title = styled.h1`
  font-size: 6em;
  margin-top: 6vh;
  @media(max-width: 1011px) {
    font-size: 3em;
    margin-top: 10vh;
    width: 100%;
    text-align: center;
    font-weight: bold;
  }
`;


export const TextArea = styled.div`
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 55%;
  @media(max-width: 1011px) {
    width: 80%;
  }
`;

export const ButtonsArea = styled.div`
  width: 100%;
  
  @media(max-width: 1011px) {
    width: 80%;
    margin-top: 18px;
    display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
    button{
      width: 250px;
      height: 50px;
    }
  }
`;


export const Background = styled.section`
  flex: 1;
  background: url(${dashboardKid}) no-repeat local;
  background-size: cover;
  @media(max-width: 1011px) {
  flex: 1;
  background: url(${dashboardKid}) no-repeat cover;
  height: 40vh;
  }
`;

export const SobreNos = styled.div`
  background: #FFFF;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  @media(max-width: 1011px) {
  height: calc(100% + 52em );
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  }
`;

export const BackgroundIconLeft = styled.section`
  flex:1;
  background: url(${leftBlue}) no-repeat center;
  background-size: cover;
  height: 950px;
  img{
    position: relative;
    left: 5vw;
    top: 60px;
    box-shadow: -25px 40px 10px #FFE830;
    border-radius: 8px;
    margin-bottom: 80px;
  }
  @media(max-width: 1011px) {
    background: none;
    background-size: cover;
    height: 350px;
  img{
    position: static;
    box-shadow: -20px 25px 4px #FFE830;
    border-radius: 8px;
    width: 210px;
  }
  }
`;

export const SobreTexto = styled.section`
  color: red;
  width: 59%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  div{
    width: 80%;
    position: relative;
    bottom: 100px;
    h1{
      color: #256EFF;
      font-size: 7em;
    }
    p{
      color: #313131;
      padding: 15px 0;
    }
  }
  @media(max-width: 1011px) {
    width: 90%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  div{
    width: 100%;
    text-align: center;
    position: relative;
    bottom: 0px;
    h1{
      color: #256EFF;
      font-size: 3em;
    }
    p{
      color: #313131;
      padding: 15px 0;
    }
  }
  
  }
`;

export const EntreContato = styled.div`
  background: transparent;
  height:100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  hr{
    width: 15vw;
    border: 7px solid #FFE830;
    border-radius: 8px;
    margin: 18px 0;
  }

  h1{
    font-size: 4.5em;
    width: 50%;
    text-align: center;
  }

  div{
    margin-top: 5vh;
    display: flex;
    justify-content: space-around;
    width: 25%;

    img{
      cursor: pointer;
      height: 50px;
    }
  }

  @media(max-width: 1011px) {
    margin: 15vh 0;
    height:100%;
    hr{
    width: 30vw;
    border: 3px solid #FFE830;
    margin: 18px 0;
  }

  h1{
    font-size: 2.5em;
    width: 90%;
    text-align: center;
    font-weight: bold;
  }

  div{
    width: 70%;

    img{
      cursor: pointer;
    }
  }
  }

`;