import React from "react";
import { Switch } from "react-router-dom";
import Route from "./Route";

import Login from "../pages/Login/Login";
//import Cadastro from "../pages/Cadastro/Cadastro"; -> Implementar no futuro
import Dashboard from "../pages/Dashboard/Dashboard";
import Loja from "../pages/Loja/index";
import MeuConteudo from "../pages/MeuConteudo/index";
import Gibi from "../pages/Gibi/index";
import GibiLIBRAS from "../pages/GibiLIBRAS/index";
import GibiEn from "../pages/GibiEN/index";
import GibiAudio from "../pages/GibiAudio/index";
import Manual from "../pages/Manual/index";
import Leitura from "../pages/Leitura/index";
import LeituraLIBRAS from "../pages/LeituraLIBRAS/index";
import LeituraEn from "../pages/LeituraEn/index";
import LeituraAudio from "../pages/LeituraAudio/index";

const Routes: React.FC = () => (
  <Switch>
    <Route path="/" exact component={Login} />
    {/**Route path="/cadastro" component={Cadastro}/>*/}
    <Route path="/dashboard" component={Dashboard} />
    <Route path="/loja" component={Loja} />
    <Route path="/meuconteudo" component={MeuConteudo} />
    <Route path="/gibi" exact component={Gibi} />
    <Route path="/gibi/libras" component={GibiLIBRAS} />
    <Route path="/gibi/en" component={GibiEn} />
    <Route path="/gibi/audio" component={GibiAudio} />
    <Route path="/manual" component={Manual} />
    <Route path="/leitura/gibiPT" exact component={Leitura} />
    <Route path="/leitura/en" component={LeituraEn} />
    <Route path="/leitura/audio" component={LeituraAudio} />
    <Route path="/leitura/libras" component={LeituraLIBRAS} />
  </Switch>
);

export default Routes;
