import React, {
    InputHTMLAttributes,
    useEffect,
    useCallback,
    useRef,
    useState,
} from "react";
import {IconBaseProps} from "react-icons";
import {FiAlertCircle} from "react-icons/fi";
import {useField} from "@unform/core";
import {Container} from "./styles";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
    name: string;
    icon?: React.ComponentType<IconBaseProps>;
}

const Input: React.FC<InputProps> = ({name, icon: Icon, ...rest}) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const [isFocused, setIsFocused] = useState(false);
    const [isField, setIsField] = useState(false);
    const {fieldName, defaultValue, error, registerField} = useField(name);

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef.current,
            path: "value",
        });
    }, [fieldName, registerField]);

    const handleInputFocus = useCallback(() => {
        setIsFocused(true);
    }, []);

    const handleInputBlur = useCallback(() => {
        setIsFocused(false);

        setIsField(!!inputRef.current?.value);
    }, []);

    return (
        <Container isErrored={!!error} isField={isField} isFocused={isFocused}>
            {Icon && <Icon size={20}/>}
            <input
                onFocus={handleInputFocus}
                onBlur={handleInputBlur}
                defaultValue={defaultValue}
                ref={inputRef}
                {...rest}
            />
            {error && <FiAlertCircle color="#c53030" size={20}/>}
        </Container>
    );
};

export default Input;
