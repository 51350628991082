import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import logoImg from "../../assets/marciologo.png";

import { Container, Itens, Hr, Logo } from "./styles";

const Header: React.FC = () => {
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const tamanho = document.body.clientWidth;

    if (tamanho <= 1011) {
      setIsMobile(true);
    }
  }, []);
  return (
    <>
      <Container isMobile={isMobile}>
        <Link to="/dashboard">
          <Logo>
            <img src={logoImg} alt="Sea Friends" />
          </Logo>
        </Link>

        <Itens
          isMobile={isMobile}
          onClick={() => {
            const tamanho =
              window.innerWidth ||
              document.documentElement.clientWidth ||
              document.body.clientWidth;
            if (tamanho <= 1011) {
              setIsMobile(!isMobile);
            }
          }}
        >
          <div>
            <Link to="/dashboard">Home</Link>
            <Link to="/loja">Loja</Link>
            <Link to="/manual">Manual do Docente</Link>
            <a href="#sobrenos">Sobre nós</a>
            <a href="#contato">Contato</a>
          </div>
        </Itens>
      </Container>
      <Hr />
    </>
  );
};

export default Header;
