const manual = [
  {
    id: "1",
    image: require("../assets/manualpt.png").default,
  },
  {
    id: "2",
    image: require("../manualPages/pag1.png").default,
  },
  {
    id: "3",
    image: require("../manualPages/pag2.png").default,
  },
  {
    id: "4",
    image: require("../manualPages/pag3.png").default,
  },
  {
    id: "5",
    image: require("../manualPages/pag4.png").default,
  },
  {
    id: "6",
    image: require("../manualPages/pag5.png").default,
  },
  {
    id: "7",
    image: require("../manualPages/pag6.png").default,
  },
  {
    id: "8",
    image: require("../manualPages/pag7.png").default,
  },
  {
    id: "9",
    image: require("../manualPages/pag8.png").default,
  },
];

export default manual;
