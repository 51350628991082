import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  flex-direction: column;
  background: #256EFF;
 
`;
export const SubContainer = styled.div`
  height: 100%;
  margin-top: 90px;
  display: flex;
  display: flex;
  justify-content: center;
  align-items: center;
  div{
    width: 80%;

    h1{
      font-size: 70px;
    }
    p{
      font-size: 22.51px;
    }
  }

  @media(max-width: 1011px) {
    flex-direction: column;

   div{
    h1{
      font-size: 2.5em;
      text-align: center;
      font-weight: bold;
    }
    p{
      text-align: center;
      font-size: 1em;
      position: relative;
      top: 18px;
    }
   }
  }
`;

export const Gibis = styled.div`
  margin-top: 90px;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  height: 100%;

  div{
    margin: 0 18px;
  }

  @media(max-width: 1011px) {
    flex-direction: column !important;
    background: #FFE830;
  }

`;

export const Card = styled.div`
  flex-direction: column;
  display: flex;
  img{
    //z-index: 5;
    box-shadow: 0px -20px 0px #FFE830;
    background-color: #FFE830;
    border-radius: 8px;
  }
  span{
    width: 100%;
    text-align: center;
    color: black;
    border-radius: 8px;
    position: relative;
    top: 2px;
  }
  section{
    background: #005BC1;
    width: 100%;
    position: relative;
    bottom: 200px;
    border-radius: 8px;
    z-index: 25;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
   
    hr{
      width: 25%;
      border: 2px solid white;
    }
  }
  @media(max-width: 1011px) {
    flex-direction: column;
    img{
    //z-index: 5;
    box-shadow: 0px -20px 0px #256EFF;
    background-color: #256EFF;
    border-radius: 8px;
  }
    section{
      hr{
      width: 50%;
      border: 1px solid white;
      }  
    }
  }

`;

export const Botao = styled.span`
    button{
      width: 150px;
      height: 40px;
      font-size: 16px;
    }
    background: #005BC1 !important;
    display: flex;
    justify-content: flex-start;
    width: 80% !important;
   @media(max-width: 1011px) {
     justify-content: center;
     align-items: center;
   }
`;