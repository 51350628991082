import React from "react";
import Button from "../../components/button/button";

import {
  Container,
  Content,
  Background,
  Info,
  ButtonsArea,
  TextArea,
} from "./styles";

import { FiLogOut } from "react-icons/fi";
import { IoCheckmarkCircleOutline } from "react-icons/io5";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";

const Gibi: React.FC = () => {
  return (
    <>
      <Container>
        <Header />
        <Content>
          <Background />
          <Info>
            <TextArea>
              <section>
                <h1>Sea Friends - Inglês</h1>
                <hr />
              </section>
              <div>
                <p>
                  Uma versão totalmente em inglês,onde você aprenderá sobre
                  Biologia Marinha.
                </p>
              </div>
            </TextArea>

            <ButtonsArea>
              <Button
                type="submit"
                icon={<FiLogOut />}
                to="/leitura/en"
                h="66px"
                w="358px"
              >
                Ler gibi
              </Button>
              <Button
                type="submit"
                icon={<IoCheckmarkCircleOutline />}
                to="/gibi"
                h="66px"
                w="358px"
                href="mailto:seafriendsproject@gmail.com"
              >
                Dar feedback
              </Button>
            </ButtonsArea>
          </Info>
        </Content>
      </Container>
      <Footer />
    </>
  );
};

export default Gibi;
