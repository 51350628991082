import styled from 'styled-components';
import gibiIMG from '../../assets/gibiIMG.png';


export const Container = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #FFE830;
  @media(max-width: 1011px) {
    height: 100%;
  }
  section{
    margin-top: 12vh;
  }
`;


export const Content = styled.section`
  display: flex;
  height: 761px;
  align-items: center;
  justify-content: center;
  width: 1045px;
  background: #256EFF;
  border-radius: 18.3px;
  @media (max-width: 1011px){
    flex-direction: column;
    width: 90%;
    height: 80%;
    margin: 18px 0;
  }
`;

export const Info = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;

export const TextArea = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  width: 70%;
  justify-content: center;
  align-items: center;
  min-width:27vw;
  height: 100%;
  div{
    text-align: start;
    width: 90%;
    justify-content: center;
    align-items: center;
  }
  h1{
      font-size: 2em;
  }
  hr{
      border: 2px solid white;
      border-radius: 8px;
      width: 15%;
      margin: 20px 0;
  }
  p{
      font-size: 1em;
      font-weight: 300;
  }
  @media (max-width: 1011px) {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    h1{
      font-weight: bold;
    }
    hr{
        width: 35%;
        margin: 20px auto;
    }
    p{
        font-size: 0.8em;
        font-weight: 300;
    }
  }
`;

export const ButtonsArea = styled.div`
  margin-top: 5vh;
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @media (max-width: 1011px){
    button{
      width: 250px;
      height: 50px;
    }
    margin: 18px 0;
  }
`;

export const Background = styled.section`
  height: 100%;
  width: 400%;
  background: url(${gibiIMG}) no-repeat center;
  background-size: cover;
  border-radius: 18px;
  @media (max-width: 1011px){
    display: none;
  }
  margin-bottom: 12vh;
`;
