import styled, {keyframes} from 'styled-components';
import {shade} from 'polished';
import singInImg from '../../assets/sign-in-background.png';

export const Container = styled.div`
  height: 100vh;
  display: flex;
  align-items: stretch;
`;

export const Content = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  place-content: center;
  width: 100%;
  max-width: 650px;
`;

const appearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const AnimationContainer = styled.div`
  flex-direction: column;
  align-items: center;
  place-content: center;
  animation: ${appearFromLeft} 1s;
  form {
    margin: 80px 0;
    width: 320px;
    text-align: center;
    > img {
      margin-bottom: 28px;
    }
    h1 {
      margin-bottom: 24px;
    }
    a {
      color: #f4edeb;
      display: block;
      margin-top: 14px;
      text-decoration: none;
      transition: color 0.2s;
      &:hover {
        color: ${shade(0.2, '#f4edeb')};
      }
    }
  }
  > a {
    color: #000000;
    display: block;
    text-decoration: none;
    transition: color 0.2s;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 23px;
    &:hover {
      color: ${shade(0.2, '#000000')};
    }
    svg {
      margin-right: 16px;
    }
  }
  h1{
    color: black;
  }
 
`;

export const Background = styled.section`
  flex: 1;
  background: url(${singInImg}) no-repeat center;
  background-size: cover;
  display: flex;
  justify-content: flex-start;
  h1{
    padding-left: 10vw;
    padding-bottom: 25vh;
    font-size: 72px;
    font-weight: 600;
    span{
      color: #FFE830;
    }
    hr{
      border: 4px solid white;
      width: 60%;
      margin-top: 18px;
      border-radius: 2px;
    }
  }
  align-items: center;
  @media(max-width: 1011px) {
    display: none;
  }
`;