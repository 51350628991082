/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from "react";
import { Container, Content, ButtonsArea } from "./styles";

import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";

import gibiPT from "../../utils/gibiPT";

const Leitura: React.FC = () => {
  const [count, setCount] = useState(0);
  const [thisPage, setThisPage] = useState([gibiPT[count]]);

  const nextPage = useCallback(() => {
    if (count < 18) {
      const newCont = count + 1;
      setCount(newCont);
      setThisPage([gibiPT[newCont]]);
      return;
    } else {
      setCount(18);
    }

    setThisPage([gibiPT[count]]);
  }, [thisPage]);

  const backPage = useCallback(() => {
    if (count >= 1) {
      const newCont = count - 1;
      setCount(newCont);
      setThisPage([gibiPT[newCont]]);
      return;
    } else {
      setCount(0);
    }
    setThisPage([gibiPT[count]]);
  }, [thisPage]);

  useEffect(() => {}, [nextPage, thisPage, backPage, count, setCount]);
  return (
    <>
      <Container>
        <Header />
        <Content>
          {thisPage.map((page) => {
            return (
              <section key={page.id}>
                <span>
                  <img src={page.imagePT} alt={`Pagína ${page.id} do Gibi`} />
                </span>
                <ButtonsArea>
                  <span onClick={backPage}>
                    <AiOutlineArrowLeft />
                  </span>
                  Pag {count + 1} de 19
                  <span onClick={nextPage}>
                    <AiOutlineArrowRight />
                  </span>
                </ButtonsArea>
              </section>
            );
          })}
        </Content>
      </Container>
      <Footer />
    </>
  );
};

export default Leitura;
